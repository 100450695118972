var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Reviews")]),_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_vm._v("Filter Reviews")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"รหัสบิล","hide-details":"","outlined":"","dense":""},model:{value:(_vm.billno),callback:function ($$v) {_vm.billno=$$v},expression:"billno"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"items":_vm.employees,"label":"ชื่อพนักงาน","hide-details":"","outlined":"","dense":""},model:{value:(_vm.employee),callback:function ($$v) {_vm.employee=$$v},expression:"employee"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"label":"ความคิดเห็น","hide-details":"","outlined":"","dense":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}})],1)],1)],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v("Average Scores")]),_c('v-card-text',[_c('v-row',_vm._l((_vm.averageScore),function(score){return _c('v-col',{key:score.id,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"subtitle-1",style:({ textAlign: 'center' })},[_vm._v(" "+_vm._s(score.name.th)+" ")]),_c('v-card-text',[_c('v-row',{style:({ alignItems: 'center' }),attrs:{"justify":"center"}},[_c('v-rating',{attrs:{"value":score.score,"color":"amber","dense":"","half-increments":"","readonly":"","size":"20"}}),_c('div',{staticClass:"ml-3 text-center"},[_vm._v(" "+_vm._s(score.score.toFixed(2))+" ")])],1)],1)],1)],1)}),1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reviews,"items-per-page":5},scopedSlots:_vm._u([{key:"item.scores",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-rating',{attrs:{"value":_vm.getAverageScore(item.scores),"dense":"","half-increments":"","readonly":"","size":"20"}})],1)]}}],null,true)},[_c('span',_vm._l((item.scores),function(score){return _c('div',{key:score.id},[_vm._v(" "+_vm._s(score.name.th)+": "+_vm._s(score.score)+" ")])}),0)])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(item.createdAt))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }