<template>
  <div>
    <h1>Reviews</h1>
    <v-card class="mb-2">
      <v-card-title>Filter Reviews</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="billno"
              label="รหัสบิล"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-select
              v-model="employee"
              :items="employees"
              label="ชื่อพนักงาน"
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="4"
          >
            <v-text-field
              v-model="comment"
              label="ความคิดเห็น"
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-4">
      <v-card-title>Average Scores</v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            v-for="score in averageScore"
            :key="score.id"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card outlined>
              <v-card-title
                :style="{ textAlign: 'center' }"
                class="subtitle-1"
              >
                {{ score.name.th }}
              </v-card-title>
              <v-card-text>
                <v-row
                  justify="center"
                  :style="{ alignItems: 'center' }"
                >
                  <v-rating
                    :value="score.score"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="20"
                  ></v-rating>
                  <div class="ml-3 text-center">
                    {{ score.score.toFixed(2) }}
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="reviews"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:item.scores="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
            >
              <v-rating
                :value="getAverageScore(item.scores)"
                dense
                half-increments
                readonly
                size="20"
              ></v-rating>
            </v-btn>
          </template>
          <span>
            <div
              v-for="score in item.scores"
              :key="score.id"
            >
              {{ score.name.th }}: {{ score.score }}
            </div>
          </span>
        </v-tooltip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ formatDateTime(item.createdAt) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Reviews',
  data() {
    return {
      reviews: [],
      averageScore: [],
      employees: [],
      employee: '',
      comment: '',
      billno: '',
      headers: [
        { text: 'รหัสบิล', value: 'billno', align: 'start' },
        { text: 'พนักงาน', value: 'employee', align: 'start' },
        { text: 'คะแนน', value: 'scores', align: 'center' },
        { text: 'ความคิดเห็น', value: 'comment', align: 'start' },
        { text: 'วันที่', value: 'createdAt', align: 'center' },
      ],
    }
  },
  watch: {
    billno() {
      this.getreviews()
    },
    employee() {
      this.getreviews()
    },
    comment() {
      this.getreviews()
    },
  },
  mounted() {
    this.getreviews()
    this.getemployees()
  },
  methods: {
    formatDateTime(date) {
      if (!date) return ''
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }

      return new Date(date).toLocaleString('th-TH', options)
    },
    getAverageScore(scores) {
      if (!scores || scores.length === 0) return 0
      const totalScore = scores.reduce((sum, score) => sum + score.score, 0)

      return totalScore / scores.length
    },
    async getreviews() {
      const res = await this.axios.get('/reviews', {
        params: {
          billno: this.billno,
          employee: this.employee,
          comment: this.comment,
        },
      })
      this.reviews = res.data.reviews
      this.averageScore = res.data.averageScoreFactors
      console.log(this.averageScore)
    },
    async getemployees() {
      const res = await this.axios.get('/employees')
      this.employees = res.data.employees
      console.log(this.employees)
    },
  },
}
</script>
